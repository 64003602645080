import {mapGetters, mapActions} from 'vuex';

export default {
    props: ['orderData'],
    data() {
        return {
            typeShipmentPlace:false,
            checkedList: [],
            form: {
                api_type: 'new_post',
                order_id: 0,
                type: 'WarehouseWarehouse',
                firstname: "",
                lastname: "",
                middlename: "",
                cabinet_id: 0,
                sender_name: '',
                areas_agent: '',
                city_agent: '',
                warehouses_agent: '',
                payer_name: '',
                bayer_areas: '',
                street_guid: '',
                bayer_house: '',
                bayer_kv: '',
                phone: '',
                payer: 'Recipient',
                payer_back: 'Recipient',
                payment_form: 0,
                shipping_date: this.$moment(),
                back_delivery: false,
                special_cargo: false,
                after_payment_on_goods_cost: true,
                delivery_object: 0,
                description_object: 0,
                back_delivery_method: 'Money',
                description: '',
                dop: '',
                cost: 0,
                area_guid: '',
                city_guid: '',
                warehouse_guid: '',
                seats: [],
                address:'',
                house:'',
                flat:'',
                city:'',
            },
            addressList:[],
            deliveryObject: [
                {
                    id: 0,
                    title: 'Посылка'
                }
            ],
            descriptionObject: [
                {
                    id: 0,
                    title: 'Постельное белье'
                }
            ],
            shipmentDate: [
                {
                    id: this.$moment(),
                    title: 'Сегодня (' + this.$moment().format('DD.MM.YYYY') + ')'
                },
                {
                    id: this.$moment().add(1, 'days'),
                    title: 'Завтра (' + this.$moment().add(1, 'days').format('DD.MM.YYYY') + ')',
                },
                {
                    id: this.$moment().add(2, 'days'),
                    title: 'Послезавтра (' + this.$moment().add(2, 'days').format('DD.MM.YYYY') + ')',
                },
            ],
            backShipment: [
                {
                    id: 'Money',
                    title: 'Денежный перевод'
                },
            ],
            payer: [
                {
                    id: 'Recipient',
                    title: 'Получатель'
                },
                {
                    id: 'Sender',
                    title: 'Отправитель'
                },
            ],
            paymentForm: [
                {
                    id: 0,
                    title: 'Наличными'
                },
                {
                    id: 1,
                    title: 'Безналичными'
                },
            ],
            type: [
                {
                    title: 'Отделение-Отделение',
                    id: 'WarehouseWarehouse',
                },
                {
                    title: 'Отделение-Адрес',
                    id: 'WarehouseDoors',
                },
                {
                    title: 'Адрес-Отделение',
                    id: 'DoorsWarehouse',
                },
                {
                    title: 'Адрес-Адрес',
                    id: 'DoorsDoors',
                },
            ],
            cabinet: [
                {
                    title: 'Terry-shop',
                    id: 0,
                }
            ],
            npAreasList: [],
            npCityList: [],
            npWarehousesList: [],
            npWarehousesListClient: [],
            npCityListClient: [],
            productCategoriesList: [],
            address:[],
            addressSender:null,
            allList:{
                width:0,
                height:0,
                length:0,
                weight:0,
                count:1,
                weightValue:0,
                volume:0,
            },
            allValue: 0,
        }
    },
    computed: {
        ...mapGetters({
            npCity: 'plugins/npCity',
            options: 'config/options',
            npAreas: 'plugins/npAreas',
            npWarehouses: 'plugins/npWarehouses',
            productCategories: 'catalog/productCategories',
            counterpartyAddress: 'plugins/counterpartyAddress',
        })
    },
    watch: {
        options(e) {
            if (e) {
                this.getCounterpartyAddress({counterparty_id: e.models.np_counterparty_sender})
                this.form.sender_name = e.models.np_firstname + ' ' + e.models.np_lastname + ' ' + e.models.np_middlename;
                this.form.sender_name += ' (' + e.models.np_phone + ')';
                this.form.areas_agent = e.models.np_areas_list_id
                this.getNpCityAction(e.models.np_areas_list_id, true);
                this.getNpWarehouseAction(e.models.np_city_list_id, true);
                this.form.city_agent = e.models.np_city_list_id
                this.form.warehouses_agent = e.models.np_city_warehouses_id
            }
        },
        counterpartyAddress(e){
            this.address = [];
            let key;
            for(key in e.models){
                this.address.push({
                    id: key,
                    title: e.models[key],
                })
            }
            this.addressSender = this.address[0].id;
        },
        productCategories(e) {
            if (e) {
                e.data.map(item => {
                    this.productCategoriesList.push({
                        id: item.id,
                        title: item.title,
                        default_width: item.default_width,
                        default_height: item.default_height,
                        default_length: item.default_length,
                        default_weight: item.default_weight,
                    })
                    if (item.children) {
                        item.children.map(itemChild => {
                            this.productCategoriesList.push({
                                id: itemChild.id,
                                title: itemChild.title,
                                default_width: itemChild.default_width,
                                default_height: itemChild.default_height,
                                default_length: itemChild.default_length,
                                default_weight: itemChild.default_weight,
                            })
                        })
                    }
                })
            }
            this.getProductSizes();
            this.getAllProducts();
        },
    },
    created() {
        this.getProductCategories();
        this.getOptions();
        this.getNpAreas().then(res => {
            if (res.status) {
                let key;
                for (key in res.data.models) {
                    this.npAreasList.push({
                        id: key,
                        title: res.data.models[key]
                    })
                }
            }
        });
        this.getOrderInfo();
        this.getAllProducts();
    },
    methods: {
        changeGoodsCost(){
            if(this.form.after_payment_on_goods_cost){
                this.form.back_delivery = false
            }
        },
        changeBackDelivery(){
            if(this.form.back_delivery){
                this.form.after_payment_on_goods_cost = false
            }
        },
        getAllProducts(){
            this.allList = {
                width:0,
                height:0,
                length:0,
                weight:0,
                count:1,
                weightValue:0,
                volume:0,
            }
            this.orderData.products.map(item=>{
                if(+this.allList.width < +item.product.default_width){
                    this.allList.width = item.product.default_width ? Number(item.product.default_width) : 0;
                }
                if(+this.allList.length < +item.product.default_length){
                    this.allList.length = +item.product.default_length;
                }

                this.allList.height += item.product.default_height ? Number(item.product.default_height) : 0;
                this.allList.weight += item.product.default_weight ? Number(item.product.default_weight) : 0;
            })
            this.getAllValue();
        },
        getAllValue(){
            this.allList.volume = Number((this.allList.height * this.allList.length * this.allList.width) / 1000000).toFixed(4)
            this.allList.weightValue = Number((this.allList.height * this.allList.length * this.allList.width) / 4000).toFixed(4)
        },
        checkAllItems(){
            if(this.checkedList.length >= 2){
                let newProduct = {
                    title:'',
                    price:0,
                    quantity:1,
                    id:0,
                    product:{
                        default_width:0,
                        default_length:0,
                        default_height:0,
                        default_weight:0,
                    }
                }

                this.checkedList.map(item=>{
                    if(+item.product.default_width >= +newProduct.product.default_width){
                        newProduct.product.default_width = item.product.default_width;
                    }
                    if(+item.product.default_length >= +newProduct.product.default_length){
                        newProduct.product.default_length = +item.product.default_length;
                    }
                    newProduct.product.default_height += +item.product.default_height;
                    newProduct.product.default_weight += +item.product.default_weight;
                    newProduct.title += item.title + '; ';
                    newProduct.price += +item.price * +item.quantity;
                    newProduct.id += item.id;
                    let index = this.orderData.products.indexOf(item);
                    this.orderData.products.splice(index, 1);
                })
                this.orderData.products.push(newProduct);
            }else{
                this.$toasted.show('Необходимо выбрать больше одной единицы для объединения',{
                    duration: 3000
                })
            }
        },
        sendOrderNewPost() {
            if(this.typeShipmentPlace){
                this.orderData.products.map(item=>{
                    this.form.seats.push({
                        weight:item.product.default_weight ?  item.product.default_weight : 0.1,
                        volume: this.getValue(item) ? this.getValue(item) : 0.0004,

                    })
                })
            }else{
                this.form.seats.push({
                    weight:this.allList.weight ? this.allList.weight : 0.1,
                    volume: this.allList.volume ? this.allList.volume : 0.0004 ,

                })
            }
            if(this.form.type === 'WarehouseDoors' || this.form.type === 'DoorsDoors'){
                if(this.form.address){
                    this.form.city = this.form.city_guid.title;
                    this.form.city_guid = this.form.city_guid.id;
                    this.funcSendOrder();
                }else{
                    this.$toasted.error("Поле Улица обязательно для заплнения",{
                        duration : 3000,
                        position: 'top-center',
                    });
                }
            }else{
                this.form.city = this.form.city_guid.title;
                this.form.city_guid = this.form.city_guid.id;
                this.funcSendOrder()
            }

        },
        funcSendOrder(){
            this.addOrderNewPost(this.form).then(res=>{
                if(res.status){
                    this.$emit('closePopup')
                    this.$toasted.success('ТТН успешно создан',{
                        duration : 3000
                    });
                }else {
                    if(res.data.errors){
                        let key;
                        for(key in res.data.errors){
                            this.$toasted.error(res.data.errors[key],{
                                duration : 3000
                            });
                        }
                    }else{
                        this.$toasted.error("Ошибка при создании ТТН",{
                            duration : 3000
                        });
                    }
                }
            });

        },
        getProductSizes() {
            this.orderData.products.map(item => {
                this.form.cost += item.price * item.quantity;
                this.productCategoriesList.map(itemCategory => {
                    if (item.product.category_id === itemCategory.id) {
                        item.product = {
                            id: item.product.id,
                            category_id: item.product.category_id,
                            default_width: itemCategory.default_width ? itemCategory.default_width : 0,
                            default_height: itemCategory.default_height ? itemCategory.default_height : 0,
                            default_length: itemCategory.default_length ? itemCategory.default_length : 0,
                            default_weight: itemCategory.default_weight ? itemCategory.default_weight : 0,
                            whole_width: this.getValue(item),
                        }
                    }
                })
            });
            this.form.order_id = this.orderData.id
            this.form.cost = Number(this.form.cost).toFixed(2);
        },
        getValue(item){
            this.allValue = 0;
            const result = (item.product.default_height * item.product.default_length * item.product.default_width) / 1000000
            this.allValue = Number(result).toFixed(4);
            return Number(result).toFixed(4);
        },
        changeSenderAreas() {
            this.getNpCityAction(this.form.areas_agent, true)
            this.form.city_agent = null;
            this.form.warehouses_agent = null;
        },
        changeSenderCity() {
            this.getNpWarehouseAction(this.form.city_agent, true)
            this.form.warehouses_agent = null;
        },
        changeSenderAreasClient() {
            this.getNpCityAction(this.form.area_guid, false)
            this.form.city_guid = null;
            this.form.warehouse_guid = null;
        },
        changeSenderCityClient() {
            this.getNpWarehouseAction(this.form.city_guid.id, false)
            this.form.warehouse_guid = null;
        },
        getOrderInfo() {
            const checkName = this.orderData.customer_name.split(' ');
            if (checkName.length <= 2) {
                this.$toasted.error('Некорректно указаны ФИО получателя! <br><br> Минимум требуются: Фамилия, Имя и Отчество.', {
                    duration: 3000,
                    position: 'top-center',
                });
                this.$emit('closePopup')
            } else {
                this.form.firstname = checkName[0];
                this.form.lastname = checkName[1];
                this.form.middlename = checkName[2];
                this.form.payer_name = this.orderData.customer_name;
                this.form.phone = this.orderData.phone
            }
        },
        getNpCityAction(id, sender) {
            this.getNpCity({'area_id': id}).then(res => {
                if (res.status && sender) {
                    this.npCityList = [];
                    let key;
                    for (key in res.data.models) {
                        this.npCityList.push({
                            id: key,
                            title: res.data.models[key]
                        })
                    }
                } else if (res.status && !sender) {
                    this.npCityListClient = [];
                    let key;
                    for (key in res.data.models) {
                        this.npCityListClient.push({
                            id: key,
                            title: res.data.models[key]
                        })
                    }
                }
            })
        },
        getNpWarehouseAction(id, sender) {
            this.getNpWarehouses({'city_id': id}).then(res => {
                if (res.status && sender) {
                    this.npWarehousesList = [];
                    let key;
                    for (key in res.data.models) {
                        this.npWarehousesList.push({
                            id: key,
                            title: res.data.models[key]
                        })
                    }
                } else if (res.status && !sender) {
                    this.npWarehousesListClient = [];
                    let key;
                    for (key in res.data.models) {
                        this.npWarehousesListClient.push({
                            id: key,
                            title: res.data.models[key]
                        })
                    }
                }
            })
        },
        closePopup() {
            this.$emit('closePopup')
        },
        ...mapActions({
            getProductCategories: 'catalog/getProductCategories',
            getNpAreas: 'plugins/getNpAreas',
            getNpWarehouses: 'plugins/getNpWarehouses',
            getNpCity: 'plugins/getNpCity',
            getOptions: 'config/getOptions',
            addOrderNewPost: 'orders/addOrderNewPost',
            getCounterpartyAddress:'plugins/getCounterpartyAddress',
        })
    }
}
